<template>
  <header>
    <div class="containter">
      <h1><router-link class="navLogo" to="/">Het Appelhuis</router-link></h1>
      <!-- <nav>
        <ul>
          <li>
            <router-link class="navLink" to="/">Home</router-link>
          </li>
          <li>
            <router-link class="navLink" to="/over-mij">Over mij</router-link>
          </li>
          <li>
            <router-link class="navLink" to="/tarief">Tarief</router-link>
          </li>
        </ul>
      </nav> -->
    </div>
  </header>
</template>

<style scoped>
header {
  z-index: 10;
  height: 10vh;
  background-color: var(--tertiary-color);
  border-bottom: 2px solid var(--primary-color);
  position: fixed;
  width: 100vw;
}

.containter {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-self: center;
  align-items: center;
}

li {
  margin: 0 1rem;
}

.navLogo {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.navLink {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.25rem;
}

a:hover,
a:active,
.router-link-active {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>